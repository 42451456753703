var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"footer-props":_vm.footers,"page":_vm.page,"server-items-length":_vm.totalItems,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.total,"disable-sort":true,"calculate-widths":""},on:{"update:items-per-page":_vm.updateItemPerPage,"update:page":_vm.getDataFromApi},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.from === 0 ? index + 1 : index + _vm.from)+" ")]}},(_vm.actionStatus)?{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.active === 1 || item.active === '1')?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" Approved ")]):(item.active === 2 || item.active === '2')?_c('v-chip',{attrs:{"small":"","color":"red","text-color":"white"}},[_vm._v(" Rejected ")]):_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v(" Pending ")])]}}:null,(_vm.actionShow)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.actionEdit)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","to":{ name: _vm.routeEdit, params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#20bf6b"}},[_vm._v("mdi-circle-edit-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Ubah data")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.actionDelete)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent"},on:{"click":function($event){$event.preventDefault();return _vm.removeItem(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ff4757"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Hapus data")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.actionDetail)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","to":{ name: _vm.routeDetail, params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#4b7bec"}},[_vm._v("mdi-eye-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Lihat detail")])]),_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.actionRemove)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent"},on:{"click":function($event){$event.preventDefault();return _vm.remove(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#ff4757"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Hapus data")])])]}}:null,(_vm.actionApproval)?{key:"item.approvals",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.active === 0 || item.active === '0')?_c('v-btn',_vm._g(_vm._b({staticClass:"tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium mr-1",attrs:{"dark":"","x-small":"","fab":"","disabled":_vm.loading,"color":"#6c5ce7"},on:{"click":function($event){$event.preventDefault();return _vm.handleApproved(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Terima")])]),(item.active === 1 || item.active === '1')?_c('v-btn',{staticClass:"tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium tw-cursor-text",attrs:{"dark":"","x-small":"","fab":"","color":"#6c5ce7"}},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v("mdi-check")])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"#2f3640"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.active === 0 || item.active === '0')?_c('v-btn',_vm._g(_vm._b({staticClass:"tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium",attrs:{"dark":"","outlined":"","x-small":"","fab":"","disabled":_vm.loading,"color":"#6c5ce7"},on:{"click":function($event){$event.preventDefault();return _vm.handleRejected(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#6c5ce7","small":""}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Tolak")])]),(item.active === 2 || item.active === '2')?_c('v-btn',{staticClass:"tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium tw-cursor-text",attrs:{"dark":"","outlined":"","x-small":"","fab":"","color":"#6c5ce7"}},[_c('v-icon',{attrs:{"color":"#6c5ce7","small":""}},[_vm._v("mdi-close")])],1):_vm._e()]}}:null,(_vm.actionImage)?{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"tw-cursor-pointer",attrs:{"contain":"","height":"100","width":"100","src":item.image},on:{"click":function($event){return _vm.handleModalImage(item.image)}}})]}}:null,(_vm.actionBrochure)?{key:"item.brochure",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.brochure,"target":"_blank"}},[_c('v-img',{attrs:{"contain":"","height":"50","width":"50","src":"https://ik.imagekit.io/5zmdzllq3/icons8-pdf-48_6htiyxpKY.png"}})],1)]}}:null,(_vm.actionLogo)?{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"tw-cursor-pointer",attrs:{"contain":"","height":"60","width":"60","src":item.logo},on:{"click":function($event){return _vm.handleModalLogo(item.logo)}}})]}}:null,(_vm.actionThumbnail)?{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"tw-cursor-pointer",attrs:{"contain":"","height":"60","width":"60","src":item.thumbnail},on:{"click":function($event){return _vm.handleModalThumbnail(item.thumbnail)}}})]}}:null,{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'project', query: { developer_name: _vm.toSlug(item.user.name) } }}},[_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.user.name))])])]}},{key:"item.project_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'property-detail', query: { property_name: item.slug } }}},[_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.property_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'photo-detail-property', query: { property_name: item.slug } }}},[_c('span',{staticClass:"tw-capitalize"},[_vm._v(_vm._s(item.name))])])]}},(_vm.actionEmailVerified)?{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [(item.email_verified_at)?_c('v-chip',{attrs:{"small":"","color":"success"}},[_vm._v(" Approved ")]):_c('v-chip',{attrs:{"small":"","color":"warning"}},[_vm._v(" Pending ")])]}}:null,(_vm.actionTimeCounter)?{key:"item.time_counter",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.convertTime(item.time_counter)))])]}}:null,(_vm.actionCreatedAt)?{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.moment(item.created_at).format("DD MMM YYYY - kk:mm:ss")))])]}}:null],null,true)}),_c('v-dialog',{attrs:{"max-width":"500","transition":"dialog-bottom-transition"},model:{value:(_vm.modalThumbnail),callback:function ($$v) {_vm.modalThumbnail=$$v},expression:"modalThumbnail"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","aspect-ratio":1 / 1,"src":_vm.thumbnail}}),_c('div',{staticClass:"tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.modalThumbnail = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500","transition":"dialog-bottom-transition"},model:{value:(_vm.modalImage),callback:function ($$v) {_vm.modalImage=$$v},expression:"modalImage"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","aspect-ratio":1 / 1,"src":_vm.image}}),_c('div',{staticClass:"tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.modalImage = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500","transition":"dialog-bottom-transition"},model:{value:(_vm.modalLogo),callback:function ($$v) {_vm.modalLogo=$$v},expression:"modalLogo"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-img',{attrs:{"contain":"","aspect-ratio":1 / 1,"src":_vm.logo}}),_c('div',{staticClass:"tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.modalLogo = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }