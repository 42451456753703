<template>
  <div>
    <v-skeleton-loader class="mx-auto" type="table-thead"></v-skeleton-loader>
    <v-skeleton-loader class="mx-auto" type="table-tbody"></v-skeleton-loader>
    <v-skeleton-loader class="mx-auto" type="table-tfoot"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style></style>
