<template>
  <v-row>
    <v-col cols="9" sm="9" md="8" lg="8">
      <!-- on desktop -->

      <v-btn
        v-if="show && $vuetify.breakpoint.mdAndUp"
        tile
        depressed
        color="primary"
        class="tw-capitalize tw-font-normal tw-tracking-wide tw-mr-3 tw-none"
        :to="{ name: routeCreate }"
      >
        <v-icon left dark>
          mdi-plus
        </v-icon>
        tambah data baru</v-btn
      >
      <!-- end -->

      <!-- on mobile -->
      <v-btn
        elevation="2"
        width="80"
        color="primary"
        class="tw-mr-2"
        :to="{ name: routeCreate }"
        v-if="show && $vuetify.breakpoint.smAndDown"
      >
        <v-icon color="white" dark>
          mdi-plus
        </v-icon>
      </v-btn>
      <!-- end -->

      <v-btn
        tile
        depressed
        v-if="$vuetify.breakpoint.mdAndUp"
        class="tw-capitalize tw-font-normal tw-tracking-wide"
        @click="handleRefresh"
      >
        <v-icon left dark>
          mdi-reload
        </v-icon>
        muat ulang data</v-btn
      >

      <!-- on mobile -->
      <v-btn v-else elevation="2" width="80" @click="handleRefresh">
        <v-icon dark>
          mdi-reload
        </v-icon>
      </v-btn>
      <!-- end -->
    </v-col>
    <v-col cols="3" sm="3" md="4" lg="4">
      <v-text-field
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="searchParams"
        label="Search"
        placeholder="Masukan Pencarian"
        prepend-inner-icon="mdi-magnify"
        dense
        outlined
        single-line
        hide-details
        @keypress.enter="submit"
        :disabled="loading"
      ></v-text-field>

      <!-- on mobile -->
      <v-btn v-else block elevation="2" @click.prevent="modalSearch = true">
        <v-icon dark>
          mdi-magnify
        </v-icon>
      </v-btn>

      <v-dialog
        v-model="modalSearch"
        transition="dialog-bottom-transition"
        max-width="400"
      >
        <v-card tile>
          <v-card-text class="tw-pt-5">
            <span class="tw-text-lg tw-font-medium tw-text-gray-800"
              >Search something in here</span
            >
            <v-text-field
              v-model="searchParams"
              label="Search"
              placeholder="Masukan Pencarian"
              prepend-inner-icon="mdi-magnify"
              dense
              outlined
              single-line
              hide-details
              class="tw-mt-2"
              @keypress.enter="submit"
              :disabled="loading"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end -->
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    refresh: {
      type: Function,
      required: true,
    },
    searchData: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    routeCreate: {
      type: String,
    },
  },
  data: () => ({
    searchParams: null,
    modalSearch: false,
  }),
  methods: {
    submit() {
      this.searchData(this.searchParams);
    },
    handleRefresh() {
      const routeName = this.$route.name;
      const queryParams = this.$route.query;
      const checkLengthQuery = Object.keys(queryParams).length;
      if (checkLengthQuery > 0) {
        this.$router.push({ name: routeName });
      } else {
        this.refresh();
      }
    },
  },
};
</script>

<style></style>
