<template>
  <div>
    <v-data-table
      :headers="headers"
      :footer-props="footers"
      :page="page"
      :server-items-length="totalItems"
      :items="items"
      :loading="loading"
      :items-per-page="total"
      @update:items-per-page="updateItemPerPage"
      @update:page="getDataFromApi"
      :disable-sort="true"
      calculate-widths
    >
      <template v-slot:[`item.order`]="{ index }">
        {{ from === 0 ? index + 1 : index + from }}
      </template>
      <template v-if="actionStatus" v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.active === 1 || item.active === '1'"
          small
          color="success"
        >
          Approved
        </v-chip>
        <v-chip
          v-else-if="item.active === 2 || item.active === '2'"
          small
          color="red"
          text-color="white"
        >
          Rejected
        </v-chip>
        <v-chip v-else small color="warning">
          Pending
        </v-chip>
      </template>
      <template v-if="actionShow" v-slot:[`item.actions`]="{ item }">
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="actionEdit"
              icon
              v-bind="attrs"
              v-on="on"
              color="transparent"
              :to="{ name: routeEdit, params: { id: item.id } }"
            >
              <v-icon color="#20bf6b">mdi-circle-edit-outline</v-icon>
            </v-btn>
          </template>
          <span>Ubah data</span>
        </v-tooltip>
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="actionDelete"
              icon
              v-bind="attrs"
              v-on="on"
              color="transparent"
              @click.prevent="removeItem(item.id)"
            >
              <v-icon color="#ff4757">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Hapus data</span>
        </v-tooltip>
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="actionDetail"
              icon
              v-bind="attrs"
              v-on="on"
              color="transparent"
              :to="{ name: routeDetail, params: { id: item.id } }"
            >
              <v-icon color="#4b7bec">mdi-eye-outline</v-icon>
            </v-btn>
          </template>
          <span>Lihat detail</span>
        </v-tooltip>
        <!-- remove visitor -->
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="actionRemove"
              icon
              v-bind="attrs"
              v-on="on"
              color="transparent"
              @click.prevent="remove(item.id)"
            >
              <v-icon color="#ff4757">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Hapus data</span>
        </v-tooltip>
        <!-- end -->
      </template>
      <template v-if="actionApproval" v-slot:[`item.approvals`]="{ item }">
        <!-- approve -->
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.active === 0 || item.active === '0'"
              dark
              x-small
              fab
              v-bind="attrs"
              v-on="on"
              :disabled="loading"
              @click.prevent="handleApproved(item.id)"
              color="#6c5ce7"
              class="tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium mr-1"
            >
              <v-icon color="white" small>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Terima</span>
        </v-tooltip>
        <v-btn
          v-if="item.active === 1 || item.active === '1'"
          dark
          x-small
          fab
          color="#6c5ce7"
          class="tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium tw-cursor-text"
        >
          <v-icon color="white" small>mdi-check</v-icon>
        </v-btn>
        <!-- end approve -->
        <!-- reject -->
        <v-tooltip top color="#2f3640">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="item.active === 0 || item.active === '0'"
              dark
              outlined
              x-small
              fab
              v-on="on"
              v-bind="attrs"
              :disabled="loading"
              @click.prevent="handleRejected(item.id)"
              color="#6c5ce7"
              class="tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium"
            >
              <v-icon color="#6c5ce7" small>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Tolak</span>
        </v-tooltip>
        <v-btn
          v-if="item.active === 2 || item.active === '2'"
          dark
          outlined
          x-small
          fab
          color="#6c5ce7"
          class="tw-text-xs tw-capitalize tw-tracking-normal tw-font-medium tw-cursor-text"
        >
          <v-icon color="#6c5ce7" small>mdi-close</v-icon>
        </v-btn>
        <!-- end reject -->
      </template>
      <template v-if="actionImage" v-slot:[`item.image`]="{ item }">
        <v-img
          class="tw-cursor-pointer"
          contain
          height="100"
          width="100"
          :src="item.image"
          @click="handleModalImage(item.image)"
        ></v-img>
      </template>
      <template v-if="actionBrochure" v-slot:[`item.brochure`]="{ item }">
        <a :href="item.brochure" target="_blank">
          <v-img
            contain
            height="50"
            width="50"
            src="https://ik.imagekit.io/5zmdzllq3/icons8-pdf-48_6htiyxpKY.png"
          ></v-img>
        </a>
      </template>
      <template v-if="actionLogo" v-slot:[`item.logo`]="{ item }">
        <v-img
          class="tw-cursor-pointer"
          contain
          height="60"
          width="60"
          :src="item.logo"
          @click="handleModalLogo(item.logo)"
        ></v-img>
      </template>
      <template v-if="actionThumbnail" v-slot:[`item.thumbnail`]="{ item }">
        <v-img
          class="tw-cursor-pointer"
          contain
          height="60"
          width="60"
          :src="item.thumbnail"
          @click="handleModalThumbnail(item.thumbnail)"
        ></v-img>
      </template>
      <template v-slot:[`item.user.name`]="{ item }">
        <router-link
          :to="{ name: 'project', query: { developer_name: toSlug(item.user.name) } }"
        >
          <span class="tw-capitalize">{{ item.user.name }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.project_name`]="{ item }">
        <router-link
          :to="{ name: 'property-detail', query: { property_name: item.slug } }"
        >
          <span class="tw-capitalize">{{ item.name }}</span>
        </router-link>
      </template>
      <template v-slot:[`item.property_name`]="{ item }">
        <router-link
          :to="{ name: 'photo-detail-property', query: { property_name: item.slug } }"
        >
          <span class="tw-capitalize">{{ item.name }}</span>
        </router-link>

      </template>
      <template
        v-if="actionEmailVerified"
        v-slot:[`item.email_verified_at`]="{ item }"
      >
        <v-chip v-if="item.email_verified_at" small color="success">
          Approved
        </v-chip>
        <v-chip v-else small color="warning">
          Pending
        </v-chip>
      </template>
      <template
        v-if="actionTimeCounter"
        v-slot:[`item.time_counter`]="{ item }"
      >
        <span>{{ convertTime(item.time_counter) }}</span>
      </template>
      <template v-if="actionCreatedAt" v-slot:[`item.created_at`]="{ item }">
        <span>{{
          moment(item.created_at).format("DD MMM YYYY - kk:mm:ss")
        }}</span>
      </template>
    </v-data-table>
    <!-- handle modal thumbnail -->
    <v-dialog
      v-model="modalThumbnail"
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-img contain :aspect-ratio="1 / 1" :src="thumbnail"></v-img>
        <div
          class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
        >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="modalThumbnail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- end -->
    <!-- handle modal image -->
    <v-dialog
      v-model="modalImage"
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-img contain :aspect-ratio="1 / 1" :src="image"></v-img>
        <div
          class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
        >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="modalImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- end -->
    <!-- handle modal logo -->
    <v-dialog
      v-model="modalLogo"
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-img contain :aspect-ratio="1 / 1" :src="logo"></v-img>
        <div
          class="tw-w-full tw-h-10 tw-bg-black tw-bg-opacity-25 tw-flex tw-absolute tw-z-10 tw-top-0 tw-p-1"
        >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="modalLogo = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <!-- end -->
  </div>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    footers: {
      type: Object,
      default: () => {},
    },
    updateItemPerPage: {
      type: Function,
      required: true,
    },
    getDataFromApi: {
      type: Function,
      required: true,
    },
    removeItem: {
      type: Function,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    routeEdit: {
      type: String,
    },
    routeDetail: {
      type: String,
    },
    actionShow: {
      type: Boolean,
      default: false,
    },
    actionDelete: {
      type: Boolean,
      default: false,
    },
    actionEdit: {
      type: Boolean,
      default: false,
    },
    actionDetail: {
      type: Boolean,
      default: false,
    },
    actionStatus: {
      type: Boolean,
      default: false,
    },
    actionApproval: {
      type: Boolean,
      default: false,
    },
    actionImage: {
      type: Boolean,
      default: false,
    },
    actionBrochure: {
      type: Boolean,
      default: false,
    },
    actionLogo: {
      type: Boolean,
      default: false,
    },
    actionThumbnail: {
      type: Boolean,
      default: false,
    },
    actionEmailVerified: {
      type: Boolean,
      default: false,
    },
    actionTimeCounter: {
      type: Boolean,
      default: false,
    },
    actionCreatedAt: {
      type: Boolean,
      default: false,
    },
    actionRemove: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      required: true,
    },
    approved: {
      type: Function,
    },
    rejected: {
      type: Function,
    },
    remove: {
      type: Function,
    },
  },
  data() {
    return {
      modalImage: false,
      modalThumbnail: false,
      modalLogo: false,
      image: "",
      thumbnail: "",
      logo: "",
    };
  },
  methods: {
    handleModalImage(params) {
      this.image = params;
      this.modalImage = true;
    },
    handleModalThumbnail(params) {
      this.thumbnail = params;
      this.modalThumbnail = true;
    },
    handleModalLogo(params) {
      this.logo = params;
      this.modalLogo = true;
    },
    async handleApproved(id) {
      try {
        this.$store.dispatch("auth/setLoading");
        await this.approved(id);
        this.$store.dispatch("auth/setLoading");
        this.getDataFromApi();
        this.errors = {};
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
    async handleRejected(id) {
      try {
        this.$store.dispatch("auth/setLoading");
        await this.rejected(id);
        this.$store.dispatch("auth/setLoading");
        this.getDataFromApi();
        this.errors = {};
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
    convertTime(params) {
      const time = params;
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Math.floor((time % 3600) % 60);

      let hDisplay = hours > 0 ? hours + (hours == 1 ? " jam " : " jam, ") : "";
      let mDisplay =
        minutes > 0 ? minutes + (minutes == 1 ? " menit " : " menit ") : "";
      let sDisplay =
        seconds > 0 ? seconds + (seconds == 1 ? " detik" : " detik") : "";

      let full = hDisplay + mDisplay + sDisplay;
      return full;
    },
    toSlug(params){
      return params
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        
    }
  },
};
</script>

<style></style>
