import api from "../api/api";
import Swal from 'sweetalert2'

const removeItem = {
  methods: {
    removeItem(id) {
      Swal.fire({
        title: 'Yakin hapus data ini ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("auth/setLoading");
          await api.delete(`/${this.url}/${id}`)
          this.$store.dispatch("auth/setLoading");
          Swal.fire({
            title: 'Hapus Sukses',
            text: 'Data anda telah terhapus',
            icon: 'success',
            timer: 1000
          })
          this.getDataFromApi(this.page)
        }
      })
    },
  },
}

export default removeItem